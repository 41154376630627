const showSpinner = ref(false)
const needRefresh = ref(false)

/**
 * Composable to handle pull to refresh on the client.
 *
 * You need to watch the needRefresh ref for changes to know when the user has performed a pull operation
 ```
 watch(needRefresh, async (newVal: boolean, oldVal: boolean) => {
  if (newVal === true) {
    try {
      setShowSpinner(true);
      await store.fetch();
    } finally {
      setShowSpinner(false);
    }
  }
});
```
 */
export const usePullToRefresh = () => {
  let startShowAt: Date

  function setShowSpinner(val: boolean) {
    if (val) {
      startShowAt = new Date()
      showSpinner.value = val
    } else {
      // Delay this for sometime
      const now = new Date()
      let delta = 1000 - (now - startShowAt) // Atleast show this for 1 seconds

      if (delta < 0) delta = 0
      setTimeout(() => {
        showSpinner.value = false
      }, delta)
    }
  }

  return {
    /** To be used by spinner implementation */
    showSpinner,
    /** Indicates whether the user has asked for data to be refreshed */
    needRefresh,
    /** Use this to control visibility of the spinner in the application.
     *
     *  The spinner is visible for atleast 1 second to prevent jarring UI
     */
    setShowSpinner
  }
}
